import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import { utils } from "../../../../utils/utils";

const DailyCustomerSignOff = ({ employeeWorkDays = [] }) => {
  const relevantWorkDay = employeeWorkDays.find(
    (day) =>
      day.signatureURL ||
      day.signatureCustomerName ||
      day.signatureNotProvidedReason
  );

  const {
    signatureURL,
    signatureCustomerName,
    signatureNotProvidedReason,
    signatureDate,
    updatedAt,
  } = relevantWorkDay || {};

  const noSignatureProvided =
    !signatureURL && !signatureCustomerName && !signatureNotProvidedReason;

  return (
    <ListGroup className="my-3 w-100">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center bg-lighter font-500"
        tag="div"
      >
        <span>Daily Customer Sign Off</span>
        {noSignatureProvided && (
          <Badge pill color="danger">
            Signature not provided
          </Badge>
        )}
      </ListGroupItem>
      {signatureNotProvidedReason ? (
        <>
          <ListGroupItem className="d-flex justify-content-between align-items-start">
            Reason: {signatureNotProvidedReason || "Not provided"}
            <Badge color="info">
              {utils.formatDateTime(
                signatureDate !== null ? signatureDate : updatedAt,
                "MM/DD/YYYY, h:mm a"
              )}
            </Badge>
          </ListGroupItem>
        </>
      ) : signatureURL && signatureCustomerName ? (
        <>
          <ListGroupItem className="d-flex justify-content-between align-items-start">
            <div className="d-flex flex-column flex-grow-1">
              <img
                alt={signatureCustomerName}
                className="my-3 col-12 col-md-6 col-lg-4 col-xl-3"
                src={signatureURL}
              />
              <small>
                <i>{signatureCustomerName}</i>
              </small>
            </div>
            <Badge color="info">
              {utils.formatDateTime(
                signatureDate !== null ? signatureDate : updatedAt,
                "MM/DD/YYYY, h:mm a"
              )}
            </Badge>
          </ListGroupItem>
        </>
      ) : null}
    </ListGroup>
  );
};

export default DailyCustomerSignOff;
